<template>
    <div>
        <b-button size="sm"
                  @click="$root.$children[0].openModal('json-modal', {data: row.item.json})"
                  variant="info"
                  class="mr-2"
        >
            <font-awesome-icon icon="eye"/>
        </b-button>
    </div>
</template>

<script>
export default {
    props: {
        'row': [Object],
        'refreshTable': Function
    },
}
</script>